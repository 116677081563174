import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import './App.css';
import Header from './components/Header';
import HeroSectionNew from './components/HeroSectionNew';
import Footer from './components/Footer';
import { setCookie } from './utils/cookies';
import LandingDisclaimer from './components/LandingDisclaimer';
import { createUser, saveUserQuizAnswers } from './api/api';


// Funnels
import FunnelFreelance from './funnels/freelance1/FunnelFreelance';
import FunnelAI from './funnels/ai/FunnelAI';
import FunnelAI2 from './funnels/ai2/FunnelAI2';
import FunnelAI3 from './funnels/ai3/FunnelAI3';

// Articles
import ArticlePaycheckToPaycheck from "./pages/articles/ArticleMiddleAgedDadDiscovers";
import ArticleITriedTheCareerFreedomPlan from './pages/articles/ArticleITriedTheCareerFreedomPlan';

const mainFunnelName = "freelance";
const mainFunnelVersion = "1";
const mainFunnel = `${mainFunnelName}${mainFunnelVersion}`;

// Global pages
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));


function App() {
  const location = useLocation();
  const [answers, setAnswers] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null); // Add this line

  const handleAnswer = (answer, questionNumber, question) => {
    const newAnswers = [...answers];
    newAnswers[questionNumber - 1] = answer;
    setAnswers(newAnswers);

    const userId = localStorage.getItem("userId");
    if (userId) {
      if (typeof answer === 'string') {
        answer = [answer];
      }
      saveUserQuizAnswers(userId, question, answer, mainFunnelName, mainFunnelVersion);
    }
  };

  const params = new URLSearchParams(window.location.search);

  const source = params.get("utm_source")
  if (source) {
    setCookie("utm_source", source);
  }

  const medium = params.get("utm_medium")
  if (medium) {
    setCookie("utm_medium", medium);
  }

  const campaign = params.get("utm_campaign")
  if (campaign) {
    setCookie("utm_campaign", campaign);
  }

  const content = params.get("utm_content")
  if (content) {
    setCookie("utm_content", content);
  }

  const term = params.get("utm_term")
  if (term) {
    setCookie("utm_term", term);
  }

  const gclid = params.get("gclid")
  if (gclid) {
    setCookie("gclid", gclid);
  }

  const fbclid = params.get("fbclid")
  if (fbclid) {
    setCookie("fbclid", fbclid);
  }

  let funnelName = mainFunnelName;
  let funnelVersion = mainFunnelVersion;
  if (location.pathname.includes('freelance1')) {
    funnelName = 'freelance';
    funnelVersion = '1';
  } else if (location.pathname.includes('ai1')) {
    funnelName = 'ai';
    funnelVersion = '1';
  } else if (location.pathname.includes('ai2')) {
    funnelName = 'ai';
    funnelVersion = '2';
  } else if (location.pathname.includes('ai3')) {
    funnelName = 'ai';
    funnelVersion = '3';
  }

  setCookie("funnel_name", funnelName);
  setCookie("funnel_version", funnelVersion);
  TagManager.dataLayer({
    dataLayer: {
      funnelName,
      funnelVersion,
    },
  });

  const gender = params.get("gender") || localStorage.getItem("gender");
  if (gender && !selectedGender) {
    setSelectedGender(gender);
    localStorage.setItem("gender", gender);
  }

  let userId = params.get("user_id");
  if (userId) {
    localStorage.setItem("userId", userId);
  }

  userId = localStorage.getItem("userId");
  let isCreateUserLoading = false;
  useEffect(() => {
    (async () => {
      if (userId === null && !isCreateUserLoading && !location.pathname.includes('checkout')) {
        isCreateUserLoading = true;
        const { data, status } = await createUser();
        if (status === 200) {
          localStorage.setItem('userId', data.id);
        }
        isCreateUserLoading = false;
      }
    })();
  }, [userId]);

  return (
    <div className="App">
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <div className='App__content'>
              <HeroSectionNew setSelectedGender={setSelectedGender} basePath={mainFunnel} funnelName={mainFunnelName} funnelVersion={mainFunnelVersion} />
            </div>
            <LandingDisclaimer />
            <Footer />
          </>
        }
        />

        <Route path="freelance1/*" element={<FunnelFreelance basePath={"/freelance1"} funnelName={"freelance"} funnelVersion={"1"} />} />
        <Route path="ai1/*" element={<FunnelAI basePath={"/ai1"} funnelName={"ai"} funnelVersion={"1"} />} />
        <Route path="ai2/*" element={<FunnelAI2 basePath={"/ai2"} funnelName={"ai"} funnelVersion={"2"} />} />
        <Route path="ai3/*" element={<FunnelAI3 basePath={"/ai3"} funnelName={"ai"} funnelVersion={"3"} />} />

        <Route path="/articles/paycheck-to-paycheck" element={<ArticlePaycheckToPaycheck />} />
        <Route path="/articles/i-tried-the-career-freedom-plan" element={<ArticleITriedTheCareerFreedomPlan />} />

        <Route path="/checkout" element={<Navigate to="/freelance1/checkout"/>} />

        <Route path="/terms" element={<><Header /><div className='App__content'><TermsAndConditions /></div><Footer /></>} />
        <Route path="/privacy-policy" element={<><Header /><div className='App__content'><PrivacyPolicy /></div><Footer /></>} />
        <Route path="/contact" element={<><Header /><div className='App__content'><ContactPage /></div><Footer /></>} />

      </Routes>
    </Suspense>
    </div>
  );

}

export default App;
